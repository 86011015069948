import type { ReactElement } from 'react';
import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import ClientSideRenderer from '@clearscore/shared.website-client-side-renderer';

import Layout from '../components/layout';

const LoggedOutOffersFormTemplate = (props: PageProps<Queries.LoggedOutOffersFormQuery>): ReactElement => {
    const { location, data } = props;

    return (
        <Layout
            meta={{
                title: 'ClearScore | Apply for Balance Transfer Credit Cards | ClearScore GB',
                description:
                    'Find out which credit cards you could get accepted for, without harming your credit score',
                robots: 'noindex, nofollow',
            }}
            location={location.pathname}
        >
            {({ commonModules }): ReactElement => (
                <ClientSideRenderer lazy={() => import('../components/verticals/logged-out-offers-form')}>
                    {(LoggedOutOffersForm) => (
                        <LoggedOutOffersForm
                            backgroundImage={getImage(data.backgroundImage)}
                            loaderImage={getImage(data.loaderImage)}
                            appStoreConfig={commonModules.app_store}
                            cookiePolicyConfig={commonModules.cookie_policy}
                        />
                    )}
                </ClientSideRenderer>
            )}
        </Layout>
    );
};

export const query = graphql`
    query LoggedOutOffersForm {
        backgroundImage: file(absolutePath: { regex: "/gb/logged-out-offers/leaves-background.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
        loaderImage: file(absolutePath: { regex: "/gb/logged-out-offers/leaves-square.png/" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
    }
`;

export default LoggedOutOffersFormTemplate;
